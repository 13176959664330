import React, { useRef } from "react";
import Nav from "./Nav";
import "../index.css";

const Header = () => {
  const nextSectionRef = useRef(null);

  const scrollToNextSection = () => {
    nextSectionRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <header className="header-section">
      <Nav />
      <div className="main">
        <div className="social-links">
          <a href="https://discord.gg/productionrp" title="Discord">
            <img src="/images/discord.svg" alt="Discord" className="social-icon" />
          </a>
          <a href="https://twitter.com/Production__RP" title="Twitter">
            <img src="/images/twitter.svg" alt="Twitter" className="social-icon" />
          </a>
          <a href="https://www.instagram.com/production__rp" title="Instagram">
            <img src="/images/instagram.svg" alt="Instagram" className="social-icon" />
          </a>
          <a href="https://www.tiktok.com/@productionrp" title="TikTok">
            <img src="/images/tiktok.svg" alt="TikTok" className="social-icon" />
          </a>
          <a href="https://www.threads.net/@productionrp" title="Threads">
            <img src="/images/threads.svg" alt="Threads" className="social-icon" />
          </a>
          <a href="https://www.twitch.tv/productionrp" title="Twitch">
            <img src="/images/twitch.svg" alt="Twitch" className="social-icon" />
          </a>
          <a href="https://www.youtube.com/@productionrp6933" title="YouTube">
            <img src="/images/youtube.svg" alt="YouTube" className="social-icon" />
          </a>
        </div>
        <div className="main-content">
          <h1>WELCOME TO PRODUCTIONRP</h1>
          <p>
            Dive into an exclusive roleplay server where exciting adventures,
            progressive stories, and endless fun await!
          </p>
          <div className="button-group">
            <button
              className="apply-button"
              onClick={() =>
                (window.location.href = "https://core.productionrp.org/apply")
              }
            >
              <img src="/images/pen.svg" alt="Apply" className="button-icon" /> APPLY
            </button>
            <button
              className="rules-button"
              onClick={() => (window.location.href = "/rules")}
            >
              <img src="/images/book.svg" alt="Rules" className="button-icon" /> RULES
            </button>
          </div>
        </div>
      </div>
      <div className="scroll-div" onClick={scrollToNextSection}>
        <div className="arrow">&#x21e9;</div>
        <p className="scroll-text">SCROLL FOR MORE</p>
      </div>
      <div ref={nextSectionRef} className="next-section">
        {}
      </div>
    </header>
  );
};

export default Header;
