import React from "react";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import "../css/AboutPage.css";

const AboutPage = () => {
  return (
    <div className="page-container">
      <Nav />
      <section id="aboutpage" className="aboutpage-section">
        <div className="aboutpage-container">
          <div className="aboutpage-text">
            <h2>
              About <span className="aboutpage-highlight">ProductionRP</span>
            </h2>
            <p>
              ProductionRP is a growing community centered around roleplay from
              all aspects of life on our FiveM server. Our main goal is to build
              a community focused on having quality roleplay experiences all
              round, and achieving positive interactions through laying out the
              opportunity to let that happen. We want a place where players are
              given the tools and support to be able to do whatever kind of
              entertaining roleplay that they and that the community would
              enjoy. In its most basic form, we want a server that is sleek and
              easy to use with beginner friendly, unique features that scale to
              be as in-depth as the players and developers are able to take it.
              To achieve this, we want to ensure we have an active, approachable
              and constantly developing staff team, which will grow along with
              the community. Support Staff, Moderators, Managers, Developers and
              Admins.
            </p>
            <p>
              There will be a zero-tolerance for any toxicity or comments that
              skirt that line, and healthy discussion is encouraged but will
              absolutely be moderated to avoid salt fests - we won’t leave
              people to argue with each other, and we hope that the community
              wouldn’t stand for it, either. We want to set up multiple ways for
              players to give ideas, feedback or raise concerns where necessary
              and have an active communication line with the staff team, going
              way beyond the last point of contact being “Okay, we’ll look into
              it”, and instead being transparent in what is done and why.
              Players will be respected and treated equally regardless of what
              character they play or what role they may have in a department or
              the staff team. This will be reflected in the server/community
              rules, as well as staff & report SOPs.
            </p>
            <p>
              Feature-wise, we want to make sure that every type of player, civ,
              crim, business owner or emergency service department, is given
              sufficient support from the get go. There is no primary focus on
              any particular aspect as we aim to give all the attention they
              deserve. We plan on having thorough mechanics for each of the
              departments, reasonable and achievable features for businesses and
              gangs separated into tiers based on activity, as well as plenty of
              civilian activities that anyone can partake in to encourage people
              to roleplay as living breathing characters with lives beyond "just
              a cop" or "just a biker".
            </p>
            <button
              className="join-button"
              onClick={() =>
                (window.location.href = "https://core.productionrp.org/apply")
              }
            >
              JOIN PRODUCTION
            </button>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default AboutPage;
