import React, { useState, useEffect, useRef } from "react";
import Nav from "../components/Nav";
import "../css/NotFoundPage.css";
import { useNavigate } from "react-router-dom";

const NotFoundPage = () => {
  const [dotPosition, setDotPosition] = useState({ top: "50%", left: "50%" });
  const [score, setScore] = useState(0);
  const [speed, setSpeed] = useState(1000);
  const [timeLeft, setTimeLeft] = useState(30);
  const [redirectCountdown, setRedirectCountdown] = useState(null);
  const intervalIdRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (timeLeft > 0) {
      const timerId = setInterval(() => {
        setTimeLeft((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(timerId);
    } else {
      setRedirectCountdown(5);
    }
  }, [timeLeft]);

  useEffect(() => {
    if (redirectCountdown !== null && redirectCountdown > 0) {
      const countdownId = setInterval(() => {
        setRedirectCountdown((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(countdownId);
    } else if (redirectCountdown === 0) {
      navigate("/"); 
    }
  }, [redirectCountdown, navigate]);

  useEffect(() => {
    if (timeLeft > 0) {
      const moveDot = () => {
        const top = Math.floor(Math.random() * 90) + "%";
        const left = Math.floor(Math.random() * 90) + "%";
        setDotPosition({ top, left });
      };

      intervalIdRef.current = setInterval(moveDot, speed);
      return () => clearInterval(intervalIdRef.current);
    } else {
      clearInterval(intervalIdRef.current); 
    }
  }, [timeLeft, speed]);

  const catchDot = () => {
    setScore(score + 1);
    const top = Math.floor(Math.random() * 90) + "%";
    const left = Math.floor(Math.random() * 90) + "%";
    setDotPosition({ top, left });
    setSpeed(Math.max(300, speed - 50));
  };

  return (
    <div className="not-found">
      <Nav />
      <h1>Uh...</h1>
      <p>Sorry, the page you are looking for does not exist.</p>
      <p>But here's a game to keep you entertained! :)</p>
      <div className="game-area">
        {timeLeft > 0 && (
          <div
            className="dot"
            style={{ top: dotPosition.top, left: dotPosition.left }}
            onClick={catchDot}
          ></div>
        )}
      </div>
      <p>Score: {score}</p>
      <p>
        {timeLeft > 0
          ? `Time Left: ${timeLeft} seconds`
          : `Redirecting in ${redirectCountdown} seconds`}
      </p>
    </div>
  );
};

export default NotFoundPage;
