import React from "react";
import useIntersectionObserver from "../hooks/useIntersectionObserver";
import "../index.css";

const RulesSection = () => {
  const [ref, isIntersecting] = useIntersectionObserver({
    threshold: 0.3,
  });

  return (
    <section
      id="rules"
      className={`rules-section scroll-animation ${
        isIntersecting ? "show" : ""
      }`}
      ref={ref}
    >
      <div className="rules-container">
        <div className="rules-text">
          <h2>
            VIEW <span className="highlight">OUR</span> RULES
          </h2>
          <p>
            To ensure a safe, positive, and enjoyable experience for everyone,
            we've outlined rules. These rules are subject to change at any time.
            Please familiarize yourself with these guidelines. We expect
            everyone, both players and staff, to follow these rules. If you have
            any questions, please do not hesitate to open a support ticket on
            our discord.
          </p>
          <div className="rules-button-group">
            <button
              className="apply-now-button"
              onClick={() => (window.location.href = "/rules")}
            >
              <img
                src="./images/book.svg"
                alt="Server Rules"
                className="button-icon"
              />{" "}
              SERVER RULES
            </button>
            <button
              className="apply-now-button"
              onClick={() => (window.location.href = "/crew")}
            >
              <img
                src="./images/user-secret.svg"
                alt="Crew Guidelines"
                className="button-icon"
              />{" "}
              CREW Guidelines
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RulesSection;
