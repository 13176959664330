import React, { useState } from "react";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import "../css/MeetingsPage.css";

const meetingsData = [
  {
    date: "August 11th, 2024",
    title: "Community Meeting",
    videoUrl: "https://www.youtube.com/embed/SUPgndguZC0?si=PXk4ZGe8CJU3vtdF",
  },
  {
    date: "July 18th, 2024",
    title: "ES Fleet & Uniforms",
    videoUrl: "https://www.youtube.com/embed/4RSBJnorEvc?si=jBwRkLf-tykhO9hD",
  },
];

const MeetingsPage = () => {
  const [activeMeeting, setActiveMeeting] = useState(null);

  const handleMeetingClick = (index) => {
    setActiveMeeting(index);
  };

  const closeModal = (e) => {
    if (e.target.className === "modal") {
      setActiveMeeting(null);
    }
  };

  const getYouTubeThumbnail = (url) => {
    if (!url) {
      return "https://via.placeholder.com/150";
    }
    const videoId = url.split("embed/")[1]?.split("?")[0];
    if (!videoId) {
      return "https://via.placeholder.com/150";
    }
    return `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
  };

  return (
    <div className="page-container">
      <Nav />
      <section id="meetingspage" className="meetingspage-section">
        <div className="meetingspage-container">
          <div className="meetingspage-text">
            <h2>Community Information</h2>
            <div className="meetings-grid">
              {meetingsData.map((meeting, index) => (
                <div
                  key={index}
                  className="meeting-item"
                  onClick={() => handleMeetingClick(index)}
                >
                  <div className="meeting-thumbnail">
                    <img
                      src={getYouTubeThumbnail(meeting.videoUrl)}
                      alt={meeting.title}
                    />
                    <div className="meeting-info">
                      <p className="meeting-title">{meeting.title}</p>
                      <p className="meeting-date">{meeting.date}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="small-screen-message">
              Please get a bigger screen to view the meetings.
            </div>
          </div>
        </div>
      </section>
      {activeMeeting !== null && (
        <div className="modal" onClick={closeModal}>
          <div className="modal-content">
            <div className="video-container">
              <iframe
                src={meetingsData[activeMeeting].videoUrl}
                title={meetingsData[activeMeeting].title}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
};

export default MeetingsPage;
