import React, { useState } from "react";
import Nav from "../components/Nav";
import Footer from "../components/Footer";

const crewRules = [
  {
    id: "section1",
    title: "How to Become a Crew",
    content: (
      <>
        <div className="section-block">
          <div className="section-header">
            <h2>Establish Your Group</h2>
          </div>
          <p>
            Establish your group through in-character means. Pick a location to
            hold down, develop traits and make connections. You have unlimited
            creativity when it comes to creating your own crew. Anyone can make
            a crew, but if you want to have ‘Official Status’, you need to apply
            through the link provided here:{" "}
            <a
              href="https://core.productionrp.org/crewapplication"
              target="_blank"
              rel="noopener noreferrer"
            >
              Crew Application
            </a>
            .
          </p>
          <p>Some rules to keep in mind when applying:</p>
          <ul>
            <li>
              You must maintain an active roster of at least{" "}
              <span className="highlight-gray">6</span> crew members. Please
              note who the leader(s) and co-leader(s) are within this roster.
            </li>
            <li>
              The maximum crew size is{" "}
              <span className="highlight-gray">24</span>.
            </li>
            <li>
              Your group must be actively roleplaying as a crew for{" "}
              <span className="highlight-gray">60</span> days before applying.
            </li>
            <li>
              Once the crew team reviews your application, the crew will be
              under review for approval, you will have to go through a{" "}
              <span className="highlight-gray">30-day</span> probationary period
              where you will be closely monitored for activity. A Crew Ticket
              will be created with the crew leaders at this time.
            </li>
          </ul>
          <p>
            In the ticket, you can share videos and or streams of crew activity
            that is currently or has recently occurred while under review.
          </p>
          <ul>
            <li>
              The crew team will share feedback on any issues that arise and
              look for improvement. The crew team will also share any positive
              feedback or shoutouts.
            </li>
            <li>
              At any time during the{" "}
              <span className="highlight-gray">30-day</span> probationary
              period, your application may be denied with explanation. You will
              also be given a set of expectations going forward.
            </li>
          </ul>
          <p>
            We are looking for creative and unique applications so be as
            detailed as you can about what your crew really stands for, what
            kind of activities you have done up to this point, as well as
            possible goals in the future.
          </p>
        </div>
      </>
    ),
  },
  {
    id: "section2",
    title: "Benefits of Becoming a Crew",
    content: (
      <>
        <div className="section-block">
          <div className="section-header">
            <h2>Benefits</h2>
          </div>
          <p>
            Once you become an official crew, you will be given certain
            benefits. Some of these benefits include:
          </p>
          <ul>
            <li>
              <span className="bold-text">Turf</span>: You will be allowed to
              select an official turf which will be labeled on the ‘Crew Map’.
            </li>
            <li>
              <span className="bold-text">Custom Crew Item</span>: You will be
              allowed to select a custom item that developers will assist you in
              creating. This could be a set of matching flags, biker kuttes,
              clothing, jewelry, tattoos, etc.
            </li>
            <li>
              <span className="bold-text">Crew Tickets</span>: This will allow
              you to open a direct line of communication with the crew team. You
              will be able to discuss possible roleplay situations or routes you
              wish to take within the server and the Crew Team as well as staff
              will be able to assist you.
            </li>
            <li>
              <span className="bold-text">Stash House</span>: You will be
              allowed to select a location in your chosen turf to be used as
              your stash house. This can be any location that would reasonably
              be able to contain your storage inside.
            </li>
          </ul>
          <p>
            Please note that just because these benefits are now accessible to
            you, that does not mean you will get all items as fast as possible.
            The Crew Team, Developers and Staff will respond to your requests as
            soon as they are able.
          </p>
        </div>
      </>
    ),
  },
  {
    id: "section3",
    title: "Staff Expectations for Crews",
    content: (
      <>
        <div className="section-block">
          <div className="section-header">
            <h2>Staff Expectations</h2>
          </div>
          <p>
            The expectations within the server for everyone representing a crew
            in ProductionRP are now significantly lifted. As a Crew Lead and as
            a member of ProductionRP, you are expected to follow the current
            server rules as listed in the community. Any and all Crew Leaders
            will be held responsible for their Crew Members when it comes to
            excessive rule breaks. This means that you essentially act as the
            moderators of your crew and should actively encourage your crew to
            abide by rules presented by staff. As leaders and advocates of the
            rules, you accept responsibility for each member. If excessive rule
            breaks are happening by your crew members, your crew will be given
            strikes. The strike system will be detailed below under ‘Strikes,
            Consequences and Punishment’.
          </p>
          <p>
            Remember, your primary goal is to entertain the individuals you
            interact with while embracing your role as a criminal. Dive deep
            into your crew's lore and story, going beyond mere repetition of
            crimes. Choose vehicles that align with your crew's identity and
            react to your environment authentically, as someone in your
            character's situation would. While it's enjoyable to own luxury cars
            and traverse the city, consider whether it truly fits your
            character's narrative. Build that story, add depth, and relish the
            immersive experience you contribute to creating.
          </p>
        </div>
      </>
    ),
  },
  {
    id: "section4",
    title: "Strikes, Consequences and Punishment",
    content: (
      <>
        <div className="section-block">
          <div className="section-header">
            <h2>Strikes, Consequences and Punishment</h2>
          </div>
          <p>
            This is a new system where your crew is being held responsible for
            consistent rule breaks. Strikes will be assigned to your crew for
            excessive rule breaks. Crew strikes occur when a crew has had issues
            staying within the expected RP and continued break of the same rule.
          </p>
          <p>
            We on the crew team will be working closely with the leaders in crew
            tickets to clean up any issues that have been reported and steps to
            take to avoid repeating the issues. If the same rule breaks continue
            to happen the crew will be issued a strike as defined in the bullets
            below. Do note that strikes do reduce with time and good (criminal
            RP) behavior. The crew teams focus is to guide our crews into the RP
            the community expects. We will work with you before strikes are
            given and support you and your crew when strikes occur and help the
            leaders in improving the RP.
          </p>
          <ul>
            <li>
              <span className="bold-text">First Strike</span>: serves as a
              warning and to fix any issues that are outstanding.
            </li>
            <li>
              <span className="bold-text">Second Strike</span>: crew activity
              and stash house will be locked down for a minimum of 2 weeks.
            </li>
            <li>
              <span className="bold-text">Third Strike</span>: Crew will become
              unofficial and lose all crew locked activity as well as stash
              house and all its contents.
            </li>
          </ul>
          <p>
            Please note: We also reserve the right to disband your crew at any
            time under our discretion. Those part of the crew may be blacklisted
            from joining another crew.
          </p>
        </div>
      </>
    ),
  },
  {
    id: "section5",
    title: "Crew Activity",
    content: (
      <>
        <div className="section-block">
          <div className="section-header">
            <h2>Keeping/Maintaining an Active Roster</h2>
          </div>
          <ul>
            <li>
              Each crew is responsible for keeping an up-to-date roster. If you
              add more people to your crew, the leads of your crew are expected
              to update the roster as well as notify the Crew Team in your
              ticket.
            </li>
            <li>
              If you or another member decide to leave a crew, you must wait{" "}
              <span class="highlight-gray">2 WEEKS</span> before joining another
              crew.
            </li>
            <li>
              Only <span class="highlight-gray">ONE</span> crew affiliated
              character is allowed per person. This means you cannot have two
              characters in the same crew. You are allowed to have multiple
              characters in different crews.
            </li>
            <li>
              When bringing in new characters to your crew, you are expected to
              be fully handling it all through in-character means. We do not
              want people to make a character join a crew and suddenly know
              everything about the group.
            </li>
          </ul>
        </div>
      </>
    ),
  },
  {
    id: "section7",
    title: "Crew Etiquette & Expectations",
    content: (
      <>
        <div className="section-block">
          <div className="section-header">
            <h2>Crew Etiquette & Expectations</h2>
          </div>
          <p>
            We expect Official Crews to be providing a higher quality of
            roleplay to the server. All of your actions in roleplay should have
            a solid and clear purpose. We expect all crews to have their own
            traits, personalities and qualities that they bring to the table.
            Some groups might be ‘loud’ and chaotic, jumping at any opportunity
            given to them while other groups might enjoy lurking in the shadows
            and moving silently.
          </p>
          <p>
            The Crew Team and Staff Team expect all ‘Official Crews’ to be going
            above and beyond in their crew roleplay. You are expected to be
            providing excellent roleplay to your crew, other crews and other
            characters on the server. If you have ideas for roleplay ideas,
            situations, events or more, please feel free to open a Crew Ticket
            and detail what you would like to bring to the server.
          </p>
        </div>
      </>
    ),
  },
  {
    id: "section8",
    title: "Crew Conflict & Guidelines",
    content: (
      <>
        <div className="section-block">
          <div className="section-header">
            <h2>Conflict Guidelines</h2>
          </div>
          <ul>
            <li>
              If you initiate violence on another Crew, you have a{" "}
              <span class="highlight-gray">3 hour cooldown</span>
              before you can initiate another violent act on the same Crew. The
              opposing party may retaliate but they will be on the same{" "}
              <span class="highlight-gray">3 hour cooldown</span> after their
              retaliation.
            </li>
            <li>
              Third-partying a crew that is at war with another crew and
              interfering in conflict that does not involve your crew is not
              allowed.
            </li>
            <li>
              Communicating with an attacking Crew to coordinate an immediate
              attack after they have pushed them is not allowed.
            </li>
            <li>
              When initiating conflict on another crew, you are permitted to
              have 6 people present and participating. This rule does not apply
              when you are in your own turf. If you are located in your turf,
              you can have as many people as you want. For example, if you are
              going to attack another gang in their own turf, you can have 6
              people and they can have as many as they want. If they decide to
              attack you in your turf, they can have 6 and you can have as many
              as you want.
            </li>
          </ul>
        </div>
        <div className="section-block">
          <div className="section-header">
            <h2>‘Crew’ VS ‘Crew’ War</h2>
          </div>
          <p>
            Keep in mind that Crew vs. crew Conflict that escalates to a full
            ‘War’ MUST be the LAST option if all other viable ways of roleplay
            have been explored already. There are many possible options to try
            and resolve conflict, be creative when trying to think of ways to
            resolve an issue with another crew before escalating to war.
          </p>
          <p>
            There is a difference between being in a war and having smaller
            conflicts or beef. Some groups will be known to be more violent and
            quick to aggression while other groups take a more passive approach
            to situations. It's important to read the room and react
            accordingly.
          </p>
          <p>
            Once the conflict is ongoing. If issues arise such as rule breaks or
            low-quality roleplay. We expect the leaders to work together. To do
            this. Open a crew ticket and we will pull in the opposing leader/s.
            The Crew Team will moderate the ticket as the leaders communicate
            the issues and resolve them. The Crew Team will not be dictating
            your war unless the leaders fail to resolve the issues and there has
            become a toxic environment.
          </p>
          <p>
            Something of note - if your crew is in a war and things are not
            quite going your way. Your crew begins the process of not wanting to
            wake up. Understand, it is on you and your crew to accept the loss.
            If you are sliding a crew and they are not retaliating, read the
            room and make adjustments. Set up terms that make sense. It is
            likely both sides have already taken losses to assets/arrest records
            etc. Think of a leveraged deal or work you can require the defeated
            crew to do for you all. Allow for the continuation of roleplay
            outside of the conflict.
          </p>
        </div>
        <div className="section-block">
          <div className="section-header">
            <h2>‘Crew’ VS Law Enforcement Officers Conflict</h2>
          </div>
          <p>
            Cops are to be regarded as a formidable force with greater
            capabilities than represented by the players themselves. Crews must
            understand that cops have jurisdiction over the entire State and
            their presence in your turf is not a valid reason to immediately
            escalate to shooting or robbing them.
          </p>
        </div>
        <div className="section-block">
          <div className="section-header">
            <h2>‘Crew’ VS General Public/Businesses/Civilians Conflict</h2>
          </div>
          <p>
            Player-run businesses within a crew’s claimed turf are not subject
            to any rules or laws dictated by the controlling crew that would
            prevent the business from operating its core functions.
          </p>
          <p>
            Crews may enter into protection agreements with businesses within
            their turf, but they may not force the business owner into
            agreements they do not agree with.
          </p>
          <p>
            In the event of a gunfight breaking out in a player-owned business
            between players currently engaged in an active war, all defending
            parties are allowed to return fire. For example, if Crew A initiates
            as a group of 6 against Crew B who is currently located at a
            business with multiple civilians next to them, Crew B and the
            Civilians are allowed to fight back. However, once the fight
            concludes, the involved parties should{" "}
            <span class="highlight-gray">immediately</span> move outside the
            location of the business.
          </p>
        </div>
        <div className="section-block">
          <div className="section-header">
            <h2>‘Crew’ Conflict within Prison</h2>
          </div>
          <p>
            Prison is a unique landscape where we are modifying rules to keep
            interactions moving throughout the prison experience. The following
            rules are specific to this concept:
          </p>
          <ul>
            <li>Conflict timer is reduced to 2 hours.</li>
            <li>
              Medical within the prison - respect the continuation of RP. Allow
              for medical RP to take place. This is still a secure facility
              regardless of the appearance of personnel. RP as if it is staffed.
            </li>
          </ul>
        </div>
      </>
    ),
  },
  {
    id: "section9",
    title: "Higher Power",
    content: (
      <>
        <div className="section-block">
          <div className="section-header">
            <h2>Higher Power</h2>
          </div>
          <p>
            Leaders of the crew, some food for thought. There is always a bigger
            fish.
          </p>
          <p>
            What your crew does will be noticed by those with more means and
            bigger firepower at any given moment. This could be other crews that
            appear to be similar to your own or one you have never seen. They
            could possess important connections and items that will be fruitful
            to your crew or those you associate with. Every action you take can
            determine if you are deemed a worthy business associate or be left
            in the dust and progress no further. So get your noses dirty, but
            have a nuance and a resolution to keep prying eyes away.
          </p>
        </div>
      </>
    ),
  },
];

const updateHistory = [
  {
    date: "August 9th, 2024",
    description: "Crews Rules moved to the PRP Website.",
  },
  { date: "July 26th, 2024", description: "Crews Rules Update." },
];

const CrewRulesPage = () => {
  const [activeSection, setActiveSection] = useState("welcome");
  const [searchQuery, setSearchQuery] = useState("");
  const [showHistory, setShowHistory] = useState(false);

  const toggleHistory = () => {
    setShowHistory(!showHistory);
  };

  const showSection = (sectionId) => {
    setActiveSection(sectionId);
  };

  const filterContent = (content) => {
    if (typeof content === "string") {
      return content.toLowerCase().includes(searchQuery.toLowerCase());
    } else if (React.isValidElement(content)) {
      return React.Children.toArray(content.props.children).some((child) =>
        filterContent(child)
      );
    }
    return false;
  };

  const highlightText = (text, query) => {
    if (!query) return text;
    const parts = text.split(new RegExp(`(${query})`, "gi"));
    return parts.map((part, index) =>
      part.toLowerCase() === query.toLowerCase() ? (
        <span key={index} className="rules-highlight">
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  const highlightedContent = (content) => {
    if (typeof content === "string") {
      return highlightText(content, searchQuery);
    } else if (React.isValidElement(content)) {
      return React.cloneElement(content, {
        children: React.Children.map(content.props.children, (child) =>
          highlightedContent(child)
        ),
      });
    }
    return content;
  };

  const filteredSections = crewRules.filter(
    (rule) =>
      rule.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      filterContent(rule.content)
  );

  return (
    <div className="app-container">
      <Nav />
      <div className="content">
        <div className="update-date">
          <p>
            View the{" "}
            <span
              className="date"
              onClick={toggleHistory}
              style={{ cursor: "pointer" }}
            >
              Changelog
            </span>
          </p>
          {showHistory && (
            <div className="history">
              <h3>Changelog</h3>
              <ul>
                {updateHistory.map((entry, index) => (
                  <li key={index}>
                    <strong>{entry.date}:</strong> {entry.description}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
        <div className="content-section">
          <div className="sections-menu">
            <div className="search-container">
              <input
                type="text"
                placeholder="Search rules..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="search-input"
              />
            </div>
            <select onChange={(e) => showSection(e.target.value)}>
              {filteredSections.map((rule) => (
                <option key={rule.id} value={rule.id}>
                  {rule.title}
                </option>
              ))}
            </select>
            {filteredSections.map((rule) => (
              <button
                key={rule.id}
                className={`section-btn ${
                  activeSection === rule.id ? "active" : ""
                }`}
                onClick={() => showSection(rule.id)}
              >
                {rule.title}
              </button>
            ))}
          </div>
          <div className="section-content">
            {activeSection === "welcome" && (
              <div id="welcome" className="section-details active">
                <div className="section-block">
                  <h2>Welcome to Crew Expectations!</h2>
                  <p>
                    Welcome to the Crew FAQ & Expectations page! Here you will find all the
                    necessary information on how to establish a crew, the
                    benefits of becoming an official crew, and the guidelines
                    you need to follow.
                  </p>
                </div>
              </div>
            )}
            {filteredSections.map((rule) => (
              <div
                key={rule.id}
                id={rule.id}
                className={`section-details ${
                  activeSection === rule.id ? "active" : ""
                }`}
              >
                {highlightedContent(rule.content)}
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CrewRulesPage;
