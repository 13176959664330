import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./index.css";
import Header from "./components/Header";
import AboutSection from "./components/AboutSection";
import RulesSection from "./components/RulesSection";
import FAQSection from "./components/FAQSection";
import Footer from "./components/Footer";
import RulesPage from "./js/RulesPage";
import AboutPage from "./js/AboutPage";
import MeetingsPage from "./js/MeetingsPage"; 
import NotFoundPage from "./js/NotFoundPage";
import CrewRulesPage from "./js/CrewRulesPage";

function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Header />
                <AboutSection />
                <RulesSection />
                <FAQSection />
                <Footer />
              </>
            }
          />
          <Route path="/rules" element={<RulesPage />} />
          <Route path="/crew" element={<CrewRulesPage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/community" element={<MeetingsPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
