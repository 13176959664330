import React, { useState, useEffect, useRef } from 'react';
import useIntersectionObserver from '../hooks/useIntersectionObserver';
import '../index.css';

const FAQSection = () => {
    const [ref, isIntersecting] = useIntersectionObserver({
        threshold: 0.1,
    });
    const [activeIndex, setActiveIndex] = useState(null);
    const [connectText, setConnectText] = useState('connect play.productionrp.org');
    const faqRefs = useRef([]);

    useEffect(() => {}, [isIntersecting]);

    useEffect(() => {
        if (!isIntersecting) {
            setTimeout(() => {
                ref.current.classList.add('show');
            }, 10);
        }
    }, [ref, isIntersecting]);

    const toggleFAQ = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                setConnectText('COPIED, PASTE IT INTO YOUR F8 CONSOLE');
                setTimeout(() => setConnectText('connect play.productionrp.org'), 5000);
            })
            .catch(console.error);
    };

    const faqs = [
        {
            question: 'Is ProductionRP allowlisted?',
            answer: 'Absolutely! To ensure quality and story-driven roleplay, we maintain an allowlist status. Submit your application <a href="https://core.productionrp.org/apply/" target="#">here</a>. Additional details are available on our <a href="https://discord.gg/productionrp/" target="#">discord</a> under the <b>#apply-for-allowlist</b> channel.'
        },
        {
            question: 'How long has the server been operational?',
            answer: 'Our server has been active and thriving for over <b>two</b> years. We successfully kicked off our beta on <b>May 6th, 2022</b>.'
        },
        {
            question: "What is the essence of ProductionRP?",
            answer: "ProductionRP fosters roleplaying in diverse life scenarios, beyond just traditional criminal activities. While we don't impose extreme realism, we emphasize on genuine and enjoyable roleplay for everyone."
        },
        {
            question: 'How long will it take for my application to be reviewed?',
            answer: 'While some applications get processed within a day or two, it can take up to a week for a comprehensive review. For guaranteed expedited service within 24 hours, consider purchasing a priority application.'
        },
        {
            question: 'How can I apply for a department?',
            answer: 'Apply directly through our <a href="https://discord.gg/productionrp/" target="#">discord</a>. Just ensure the department is currently accepting applications before applying.'
        },
        {
            question: "I'm a streamer. Can I skip the application process or expedite it?",
            answer: "As much as we appreciate the exposure streamers can bring, it's crucial for us to maintain equality. Every player, irrespective of their public status, must undergo the application process."
        },
        {
            question: 'How can I support ProductionRP?',
            answer: "We're grateful for any support! If you'd like to contribute, please visit our store. Remember, support is always appreciated but never obligatory."
        },
        {
            question: 'Do you use QBCore or ESX as a framework?',
            answer: 'We use a fully custom from the ground up (with a few exceptions for a small amount of third-party resources) framework. We do not depend on any open-source frameworks available to the public.'
        },
        {
            question: 'How do I appear on HasRoot and in the discord\'s streamers list when I stream ProductionRP?',
            answer: `Thank you for choosing to stream our roleplay server! The bot in our discord gives the <b>Streaming Now!</b> role to those who have the word <b>ProductionRP</b>, without spaces or typos, in the title of their stream. Having the server name in your title will also add you to our HasRoot site!
            <ol>
                <li>Ensure that your Twitch OR YouTube account is linked through your profile's integrations</li>
                <li>Make sure that your status is set to <b>Online</b> or <b>Idle</b> or <b>Do Not Disturb</b>. You cannot pop up as <b>Invisible</b></li>
                <li>Make sure that your current activity is being displayed. <a href="https://i.imgur.com/nEbIjE2.jpeg" target="#">Like This!</a></li>
                <li>Verify that <b>Streamer Mode</b> is enabled. This will usually be enabled automatically depending on your software but double-check if you have doubts!</li>
                <li>Check that the bot has posted your link in our <a href="https://discord.com/channels/888066725923418142/889166852360638494" target="#">stream announcements channel</a> and that your status circle is now purple.</li>
            </ol>
            If none of these steps work, try re-linking your Twitch OR YouTube account through your profile's integrations. It usually needs a re-link once in a while.`
        },
        {
            question: 'How do I connect to ProductionRP?',
            answer: (
                <>
                    <p>You will need to be allowlisted first otherwise you will not be able to join. Submit your application <a href="https://core.productionrp.org/apply/" target="#">here</a>. Once your application is approved or already approved, launch <b>FiveM</b>, press your <b>F8</b> key and enter:</p>
                    <div className="connect" onClick={() => copyToClipboard('connect play.productionrp.org')}>{connectText}</div>
                    <p>The server list will not always work. It is recommended to connect using this direct connect method.</p>
                </>
            )
        }
    ];

    return (
        <section
            id="faq"
            className={`faq-section scroll-animation ${isIntersecting ? 'show' : ''}`}
            ref={ref}
        >
            <h2>FREQUENTLY <span className="highlight">ASKED</span> QUESTIONS</h2>
            {faqs.map((faq, index) => (
                <div
                    className={`faq-item ${activeIndex === index ? 'active' : ''}`}
                    key={index}
                    ref={(el) => (faqRefs.current[index] = el)}
                >
                    <button className={`faq-question ${activeIndex === index ? 'active' : ''}`} onClick={() => toggleFAQ(index)}>
                        {faq.question}
                        <span className="plus-icon">+</span>
                    </button>
                    <div className={`faq-answer ${activeIndex === index ? 'active' : ''}`}>
                        {typeof faq.answer === 'string' ? (
                            <p dangerouslySetInnerHTML={{ __html: faq.answer }} />
                        ) : (
                            faq.answer
                        )}
                    </div>
                </div>
            ))}
        </section>
    );
};

export default FAQSection;