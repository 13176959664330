import React from 'react';
import '../index.css';

const Nav = () => {
    const toggleMenu = () => {
        const navLinks = document.querySelector('.nav-links');
        navLinks.classList.toggle('show');
        document.body.classList.toggle('nav-open');
        document.querySelector('.logo-item').style.display = navLinks.classList.contains('show') ? 'none' : 'flex';
    };

    return (
        <nav>
            <div className="nav-left">
                <a href="/" className="nav-logo"><img src="/images/prplogo.png" className="nav-image" alt="ProductionRP Logo" /></a>
                <div className="menu-icon" onClick={toggleMenu}>&#9776;</div>
            </div>
            <ul className="nav-links">
                <li><a href="https://doj.productionrp.org/">DOJ</a></li>
                <li><a href="https://core.productionrp.org/">CORE</a></li>
                <li className="logo-item"><a href="/"><img src="/images/prplogo.png" className="nav-image" alt="ProductionRP Logo" /></a></li>
                <li><a href="fivem://connect/play.productionrp.org">CONNECT</a></li>
                <li><a href="https://store.productionrp.org/">STORE</a></li>
            </ul>
        </nav>
    );
};

export default Nav;
